import { useRef, useState } from "react";
import { Fireworks } from "@fireworks-js/react";
import type { FireworksHandlers } from "@fireworks-js/react";
import React from "react";
import banner from "../images/banner.jpg";
import underbanner from "../images/underbanner.jpg";
import underbanner_hr from "../images/underbanner_high_res.png";
import waittime from "../images/waittime.jpg";
import hospital from "../images/hospital.jpg";
import { wait } from "@testing-library/user-event/dist/utils";

export const Input = ({}) => {
  React.useEffect(() => {
    init();
  }, []);

  const ref = useRef<FireworksHandlers>(null);
  const [disabled, setDisabled] = useState<boolean>(false);
  const [win, setWin] = useState<boolean>(false);
  const [lose, setLose] = useState<boolean>(false);

  const init = () => {
    console.log("init");
    if (!ref.current) return;
    console.log("ref stop");
    ref.current.stop();
  };

  const toggle = () => {
    if (!ref.current) return;
    if (ref.current.isRunning) {
      ref.current.stop();
    } else {
      console.log("ref start");
      ref.current.start();
    }
  };

  const go = (value: string) => {
    if (value === "1175") {
      setWin(true);
      setLose(false);
      if (!ref.current) return;
      console.log("ref start");
      ref.current.start();
    } else {
      setWin(false);
      setLose(true);
      if (!ref.current) return;
      console.log("ref stop");
      ref.current.stop();
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    console.log(e.target[0].value);
    go(e.target[0].value);
    // send state to server with e.g. `window.fetch`
  };

  return (
    <div>
      <div className="flex items-center justify-center grid grid-cols-2 ml-10 mr-10">
        <div className=" flex items-center justify-center col-span-2">
          <img className="h-20" src={banner} alt="" />
        </div>
        <div className=" flex items-center justify-center col-span-2">
          <img className="w-11/12" src={underbanner_hr} alt="" />
        </div>
        <div className="underline text-3xl bg-slate-100 mb-2">
          Virtual Waiting Room
        </div>
        <div className="underline text-3xl bg-slate-100 mb-2">
          Modern & Welcoming Design
        </div>
        <div className=" flex items-center justify-center ">
          <img className="" src={waittime} alt="" />
        </div>
        <div className=" flex items-center justify-center ">
          <img className="" src={hospital} alt="" />
        </div>
      </div>

      {/* <div className="flex items-center justify-center grid">
        <div className=" flex items-center justify-center">
          <img className="h-20" src={banner} alt="" />
        </div>
        <div>
          <img className="w-full" src={underbanner} alt="" />
        </div>
      </div> */}

      {/* <div className="flex grid items-center justify-center grid-cols-2"></div>
      <div className="flex items-center justify-center">
        <div className="flex grid items-center justify-center grid-cols-2">
          <img className="h-60" src={waittime} alt="" />
          <img className="h-60" src={hospital} alt="" />
        </div>
      </div> */}
      <div className="flex items-center justify-center grid grid-cols-2 mb-10 ml-10 mr-10">
        <div className="">
          <div className="underline text-3xl bg-slate-100">Locations</div>
          <ul className="text-left list-disc ml-5">
            <li>Cedar Park</li>
            <li>North Austin</li>
            <li>Pflugerville</li>
            <li>South Austin</li>
          </ul>
        </div>
        <form onSubmit={handleSubmit} className="z-40 mb-10">
          <div className="flex items-center justify-center mt-20">
            <div className="flex items-center justify-center border-b border-teal-500 py-2 w-7/12">
              <input
                className="appearance-none bg-transparent border-none w-full text-gray-700 mr-3 py-1 px-2 leading-tight focus:outline-none"
                type="text"
                placeholder="Enter Code"
                aria-label="Full name"
              />
              <button
                className="flex-shrink-0 bg-teal-500 hover:bg-teal-700 border-teal-500 hover:border-teal-700 text-sm border-4 text-white py-1 px-2 rounded"
                // onClick={go}
                // disabled={disabled}
                type="submit"
              >
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>

      <div className="mb-20">
        {!win && !lose && <div className="mt-20 mb-40"></div>}
        {win && (
          <div className="text-3xl">
            CONGRATULATIONS!<br></br> You have won a free pair of shoes to
            support you on the clinic floor.<br></br> Brendan's credit card
            number is <br></br>
            3462-5890-3253-4521.<br></br> Happy Shopping! 😀
          </div>
        )}
        {lose && (
          <div className="mt-5 text-xl">
            Sorry, another doctor in your practice holds the code to the free
            pair of shoes.
          </div>
        )}
      </div>

      <Fireworks
        ref={ref}
        options={{ opacity: 0.9 }}
        style={{
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          position: "fixed",
        }}
      />
    </div>
  );
};
